import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { connect, useSelector } from 'react-redux';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsDiscord, selectIsMobile } from '../../selectors/app';
import { selectIsNowPlayingDialogOpen } from '../../selectors/dialog';
import shouldShowPlayer from '../../utils/playerStatus/shouldShowPlayer';
import css from './messagePopover.module.scss';

export function MessagePopover({ message, showing, className }) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const isPlayerOpen = useSelector(shouldShowPlayer);
  const isNowPlayingDialogOpen = useSelector(selectIsNowPlayingDialogOpen);
  const isDiscord = useSelector(selectIsDiscord);
  const isMobile = useSelector(selectIsMobile);

  if (!message) {
    return null;
  }

  return (
    <div
      className={classNames(
        css.message,
        {
          [css.show]: showing,
          [css.abovePlayer]:
            isPlayerOpen &&
            (!isNowPlayingDialogOpen || (isDiscord && !isMobile)),
        },
        className,
      )}
    >
      {getLocalizedText(message)}
    </div>
  );
}

MessagePopover.propTypes = {
  showing: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export function mapStateToProps({ messagePopover }) {
  return messagePopover;
}

export default connect(mapStateToProps)(MessagePopover);
