const CaretDown = (props) => (
  <svg
    width={10}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m1.5 1.5 3.5 4 3.5-4"
      stroke="#F1F2F3"
      strokeWidth={2}
      strokeMiterlimit={10}
    />
  </svg>
);
export default CaretDown;
