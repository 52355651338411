import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import cssVariables from '../../../styles/variables';
import {
  lightnessModifier,
  validateAndGetHexColor,
} from '../../../utils/color';
import { Anchor } from '../Anchor';
import css from './pill-button.module.scss';

class PillButtonLink extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    externalHref: PropTypes.string,
    label: PropTypes.node.isRequired,
    labelClassName: PropTypes.string,
    dataTestId: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    tabIndex: PropTypes.string,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    icon: PropTypes.node,
    isSmall: PropTypes.bool,
    hasRightSideIcon: PropTypes.bool,
    tooltipId: PropTypes.string,
    tooltipText: PropTypes.string,
    backgroundColor: PropTypes.string,
    hoverColor: PropTypes.string,
    textColor: PropTypes.string,
  };

  static defaultProps = {
    labelClassName: '',
  };

  state = {
    isHovering: false,
    backupHoverColor: lightnessModifier(
      this.props.backgroundColor,
      cssVariables['--lighter-hover-luminosity-value'],
    ),
  };

  handleMouseLeave = (event) => {
    const { isDisabled, onMouseLeave } = this.props;

    if (!isDisabled) {
      if (onMouseLeave) onMouseLeave();
      this.setState({ isHovering: false });
      event.target.blur();
    }
  };

  handleMouseEnter = () => {
    const { isDisabled, onMouseEnter } = this.props;

    if (!isDisabled) {
      if (onMouseEnter) onMouseEnter();
      this.setState({ isHovering: true });
    }
  };

  render() {
    const {
      id,
      to,
      externalHref,
      dataTestId,
      className,
      isDisabled,
      type,
      tabIndex,
      onClick,
      label,
      labelClassName,
      icon,
      isSmall,
      hasRightSideIcon,
      tooltipId,
      tooltipText,
      backgroundColor,
      hoverColor,
      textColor,
    } = this.props;

    let leftSideIcon;
    let rightSideIcon;

    if (hasRightSideIcon) {
      rightSideIcon = icon;
    } else {
      leftSideIcon = icon;
    }

    const style = {};

    if (backgroundColor) {
      const hoverBgColor = hoverColor || this.state.backupHoverColor;
      style.backgroundColor = this.state.isHovering
        ? hoverBgColor
        : backgroundColor;
    }
    if (textColor) {
      style.color = validateAndGetHexColor(
        textColor,
        cssVariables['--secondary-color-5'],
      );
    }

    const LinkComponent = externalHref ? Anchor : Link;

    return (
      <LinkComponent
        to={to}
        href={externalHref}
        id={id}
        data-testid={dataTestId}
        className={classNames(css.solidButton, className)}
        disabled={isDisabled}
        type={type || 'link-button'}
        tabIndex={tabIndex || '0'}
        data-small={isSmall}
        onClick={(...args) => !isDisabled && onClick && onClick(...args)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        data-for={tooltipId}
        data-tip={tooltipText}
        style={style}
      >
        <span className={labelClassName}>
          {leftSideIcon}
          {label}
          {rightSideIcon}
        </span>
      </LinkComponent>
    );
  }
}

export default PillButtonLink;
