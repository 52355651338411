import {
  selectGdprApplies,
  selectIsGdprReady,
  selectIsMobile,
} from '../selectors/app';

export default function shouldShowFloatingGdprButton(state, isLeftSideDocked) {
  const isMobile = selectIsMobile(state);
  const gdprApplies = selectGdprApplies(state) || false;
  const isGdprReady = selectIsGdprReady(state);

  return !isMobile && !isLeftSideDocked && gdprApplies && isGdprReady;
}
