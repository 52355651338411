export class ElementEventHandlers {
  #el;

  #dragPos;

  #activeListeners = [];

  constructor(el) {
    this.#el = el;
  }

  #applyListener(listenerArgs) {
    this.#activeListeners.push(listenerArgs);
    this.#el.addEventListener(...listenerArgs);
  }

  #onWheel = (e) => {
    if (e.deltaX) {
      return;
    }
    // prevent default scroll
    e.preventDefault();
    // translate to horizontal scroll
    this.#el.scrollLeft += e.deltaY;
  };

  #onMouseMove = (e) => {
    // How far the mouse has been moved
    const dx = e.clientX - this.#dragPos.x;
    const dy = e.clientY - this.#dragPos.y;

    // Scroll the element
    this.#el.scrollLeft = this.#dragPos.left - dx;
    this.#el.scrollTop = this.#dragPos.top - dy;
  };

  #onMouseUp = () => {
    document.removeEventListener('mousemove', this.#onMouseMove);
    document.removeEventListener('mouseup', this.#onMouseUp);

    this.#el.style.cursor = 'grab';
  };

  #onMouseDown = (e) => {
    e.preventDefault();

    this.#el.style.cursor = 'grabbing';
    this.#dragPos = {
      // current scroll position
      left: this.#el.scrollLeft,
      top: this.#el.scrollTop,
      // current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', this.#onMouseMove);
    document.addEventListener('mouseup', this.#onMouseUp);
  };

  enableHorizontalWheelScroll() {
    this.#applyListener(['wheel', this.#onWheel]);
  }

  enableDragToScroll() {
    this.#applyListener(['mousedown', this.#onMouseDown]);
  }

  removeAllListeners() {
    this.#activeListeners.forEach((listenerArgs) => {
      this.#el.removeEventListener(...listenerArgs);
    });
  }
}
