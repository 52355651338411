import PropTypes from 'prop-types';
import { NavBackArrowButton } from '../../../shared/button/NavBackArrowButton';
import { NavHomeTuneInLink } from '../../../shared/link/NavHomeTuneInLink';
import css from '../browsies-header.module.scss';

export function Badge({ showBackArrow, onClick }) {
  return (
    <div className={css.primaryBadge}>
      {showBackArrow ? (
        <NavBackArrowButton onClick={onClick} />
      ) : (
        <NavHomeTuneInLink />
      )}
    </div>
  );
}

Badge.propTypes = {
  showBackArrow: PropTypes.bool,
  onClick: PropTypes.func,
};
