import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';

// exported for testing
export const renderCustomVerticalScrollbarThumb =
  (darkThemeEnabled = false) =>
  // eslint-disable-next-line react/prop-types
  ({ style, ...props }) => (
    <div
      {...props}
      style={{
        ...style,
        backgroundColor: darkThemeEnabled
          ? 'rgba(0, 0, 0, 0.55)'
          : 'rgba(255, 255, 255, 0.4)',
        borderRadius: '3px',
      }}
    />
  );

const Scrollbar = ({ width, darkThemeEnabled, children }) => (
  <Scrollbars
    universal
    hideTracksWhenNotNeeded
    autoHide
    renderThumbVertical={renderCustomVerticalScrollbarThumb(darkThemeEnabled)}
    style={{ height: '100%', width }}
  >
    {children}
  </Scrollbars>
);

Scrollbar.propTypes = {
  darkThemeEnabled: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  children: PropTypes.object.isRequired,
};

Scrollbar.defaultProps = {
  darkThemeEnabled: false,
};

export default Scrollbar;
