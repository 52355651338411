import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openOneTrustPreferenceCenter } from '../../actions/app';
import { GERMANY_COUNTRY_ID } from '../../constants/config';
import siteLinks from '../../constants/footer/siteLinks';
import socialLinks from '../../constants/footer/socialLinks';
import * as localizations from '../../constants/localizations/site';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsFord, selectTuneInUserSerial } from '../../selectors/app';
import {
  selectCountryCode,
  selectCountryId,
  selectCountryStateCode,
  selectTermsOfServiceLink,
} from '../../selectors/config';
import getCurrentYear from '../../utils/date/getCurrentYear';
import {
  isUserInCalifornia,
  isUserInWashington,
} from '../../utils/isUserInUSOrUSTerritories';
import ChevronRightLink from '../shared/link/ChevronRightLink';
import UnderlineLink from '../shared/link/UnderlineLink';
import css from './footer.module.scss';

export class Footer extends Component {
  static propTypes = {
    isRightSideOpen: PropTypes.bool,
    isLanding: PropTypes.bool,
    userIsInCalifornia: PropTypes.bool.isRequired,
    userIsInWashington: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
      openOneTrustPreferenceCenter: PropTypes.func.isRequired,
    }),
    countryId: PropTypes.string.isRequired,
    isMinimalFooter: PropTypes.bool,
    darkMode: PropTypes.bool,
    hideLogo: PropTypes.bool,
    hideProductLinks: PropTypes.bool,
    hideSitemap: PropTypes.bool,
    hideConsent: PropTypes.bool,
    hideSocial: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  /**
   * TODO: This is a temporary solution to show the speedtest button remove after Ford demo
   */
  constructor(props) {
    super(props);
    this.dialogRef = createRef();
  }

  showDialog = () => {
    if (this.dialogRef.current) {
      this.dialogRef.current.showModal();
    }
  };

  closeDialog = () => {
    if (this.dialogRef.current) {
      this.dialogRef.current.close();
    }
  };

  render() {
    const {
      isRightSideOpen,
      isLanding,
      userIsInCalifornia,
      userIsInWashington,
      countryId,
      actions,
      termsOfServiceLink,
      isMinimalFooter,
      darkMode,
      hideLogo,
      hideProductLinks,
      hideSitemap,
      hideConsent,
      hideSocial,
      showDiagnostic,
    } = this.props;
    const { getLocalizedText } = this.context;

    const consentLink = userIsInCalifornia
      ? siteLinks.oneTrustCcpaPreferenceCenter
      : siteLinks.oneTrustPreferenceCenter;

    return (
      <footer
        id="footer"
        data-testid="footer"
        className={classNames({
          [css.isLanding]: isLanding,
          [css.darkMode]: darkMode,
        })}
      >
        <div
          className={classNames(css.footer, {
            [css.noRight]: !isRightSideOpen,
            [css.isMinimal]: isMinimalFooter,
          })}
        >
          <div className={css.footerRow}>
            {!hideLogo && (
              <div
                id="footerLogoContainer"
                data-testid="footerLogoContainer"
                className={css.logoContainer}
              >
                <div
                  id="footerLogo"
                  data-testid="footerLogo"
                  className={css.logo}
                />
                <div
                  id="footerValueProp"
                  data-testid="footerValueProp"
                  className={classNames(css.valuePropContainer, {
                    [css.noRight]: !isRightSideOpen,
                  })}
                >
                  {getLocalizedText(localizations.VALUE_PROP)}
                </div>
              </div>
            )}
            {!hideProductLinks && (
              <div
                id="siteLinksContainer"
                data-testid="siteLinksContainer"
                className={css.linksContainer}
              >
                <div className={css.linksRow}>
                  <div
                    data-testid="listenLinks"
                    className={classNames(css.linksColumn, {
                      [css.noRight]: !isRightSideOpen,
                    })}
                  >
                    <ChevronRightLink
                      id={siteLinks.listenNow.id}
                      data-testid={siteLinks.listenNow.id}
                      className={css.siteLinkHeader}
                      chevronClassName={css.chevron}
                      to={siteLinks.listenNow.url}
                      injectSvg={isLanding}
                    >
                      {getLocalizedText(siteLinks.listenNow.name)}
                    </ChevronRightLink>
                    <UnderlineLink
                      id={siteLinks.sports.id}
                      data-testid={siteLinks.sports.id}
                      to={siteLinks.sports.url}
                      className={css.siteLink}
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.sports.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.music.id}
                      data-testid={siteLinks.music.id}
                      to={siteLinks.music.url}
                      className={css.siteLink}
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.music.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.news.id}
                      data-testid={siteLinks.news.id}
                      to={siteLinks.news.url}
                      className={css.siteLink}
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.news.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.podcasts.id}
                      data-testid={siteLinks.podcasts.id}
                      to={siteLinks.podcasts.url}
                      className={css.siteLink}
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.podcasts.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.audiobooks.id}
                      data-testid={siteLinks.audiobooks.id}
                      to={siteLinks.audiobooks.url}
                      className={css.siteLink}
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.audiobooks.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.more.id}
                      data-testid={siteLinks.more.id}
                      to={siteLinks.more.url}
                      className={css.siteLink}
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.more.name)}
                    </UnderlineLink>
                  </div>
                  <div
                    data-testid="aboutLinks"
                    className={classNames(css.linksColumn, {
                      [css.noRight]: !isRightSideOpen,
                    })}
                  >
                    <ChevronRightLink
                      id={siteLinks.aboutUs.id}
                      data-testid={siteLinks.aboutUs.id}
                      className={css.siteLinkHeader}
                      chevronClassName={css.chevron}
                      to={siteLinks.aboutUs.url}
                      target="_blank"
                      isNotReactRouterLink
                      injectSvg={isLanding}
                    >
                      {getLocalizedText(siteLinks.aboutUs.name)}
                    </ChevronRightLink>
                    <UnderlineLink
                      id={siteLinks.contactUs.id}
                      data-testid={siteLinks.contactUs.id}
                      className={css.siteLink}
                      to={siteLinks.contactUs.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.contactUs.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.careers.id}
                      data-testid={siteLinks.careers.id}
                      className={css.siteLink}
                      to={siteLinks.careers.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.careers.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.press.id}
                      data-testid={siteLinks.press.id}
                      className={css.siteLink}
                      to={siteLinks.press.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.press.name)}
                    </UnderlineLink>
                  </div>
                </div>
                <div className={css.linksRow}>
                  <div
                    data-testid="productLinks"
                    className={classNames(css.linksColumn, css.noLinkHeader, {
                      [css.noRight]: !isRightSideOpen,
                    })}
                  >
                    <span
                      id="footerProduct"
                      data-testid="footerProduct"
                      className={classNames(css.siteLinkHeader, css.siteLink)}
                    >
                      {getLocalizedText(localizations.PRODUCT)}
                    </span>
                    <UnderlineLink
                      id={siteLinks.support.id}
                      data-testid={siteLinks.support.id}
                      className={css.siteLink}
                      to={siteLinks.support.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.support.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.getTuneIn.id}
                      data-testid={siteLinks.getTuneIn.id}
                      className={css.siteLink}
                      to={siteLinks.getTuneIn.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.getTuneIn.name)}
                    </UnderlineLink>
                  </div>
                  <div
                    data-testid="communityLinks"
                    className={classNames(css.linksColumn, css.noLinkHeader, {
                      [css.noRight]: !isRightSideOpen,
                    })}
                  >
                    <span
                      id="footerCommunities"
                      data-testid="footerCommunities"
                      className={classNames(css.siteLinkHeader, css.siteLink)}
                    >
                      {getLocalizedText(localizations.COMMUNITIES)}
                    </span>
                    <UnderlineLink
                      id={siteLinks.brand.id}
                      data-testid={siteLinks.brand.id}
                      className={css.siteLink}
                      to={siteLinks.brand.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.brand.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.broadcasters.id}
                      data-testid={siteLinks.broadcasters.id}
                      className={css.siteLink}
                      to={siteLinks.broadcasters.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.broadcasters.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.podcasters.id}
                      data-testid={siteLinks.podcasters.id}
                      className={css.siteLink}
                      to={siteLinks.podcasters.url}
                      target="_blank"
                      isNotReactRouterLink
                      textEllipsis
                    >
                      {getLocalizedText(siteLinks.podcasters.name)}
                    </UnderlineLink>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={classNames(css.footerRow, css.noOverflow)}>
            <div
              id="footerFootnoteContainer"
              data-testid="footerFootnoteContainer"
              className={css.footnoteContainer}
            >
              <div
                id="legalContainer"
                data-testid="legalContainer"
                className={css.legalContainer}
              >
                {interpolateComponents({
                  mixedString: getLocalizedText(localizations.LEGAL),
                  components: {
                    year: (
                      <span data-testid="legalYear">{getCurrentYear()}</span>
                    ),
                  },
                })}
              </div>
              <div
                id="policiesContainer"
                data-testid="policiesContainer"
                className={css.policiesContainer}
              >
                <UnderlineLink
                  id={siteLinks.privacy.id}
                  data-testid={siteLinks.privacy.id}
                  className={css.siteLink}
                  to={siteLinks.privacy.url}
                  target="_blank"
                  isNotReactRouterLink
                >
                  {getLocalizedText(siteLinks.privacy.name)}
                </UnderlineLink>
                <UnderlineLink
                  id={siteLinks.policies.id}
                  data-testid={siteLinks.policies.id}
                  className={css.siteLink}
                  to={termsOfServiceLink}
                  target="_blank"
                  isNotReactRouterLink
                >
                  {getLocalizedText(siteLinks.policies.name)}
                </UnderlineLink>
                {countryId === GERMANY_COUNTRY_ID && (
                  <>
                    <UnderlineLink
                      id={siteLinks.germanyPrivacy.id}
                      data-testid={siteLinks.germanyPrivacy.id}
                      className={css.siteLink}
                      to={siteLinks.germanyPrivacy.url}
                      target="_blank"
                      isNotReactRouterLink
                    >
                      {getLocalizedText(siteLinks.germanyPrivacy.name)}
                    </UnderlineLink>
                    <UnderlineLink
                      id={siteLinks.germanyHowTuneInWorks.id}
                      data-testid={siteLinks.germanyHowTuneInWorks.id}
                      className={css.siteLink}
                      to={siteLinks.germanyHowTuneInWorks.url}
                      target="_blank"
                      isNotReactRouterLink
                    >
                      {getLocalizedText(siteLinks.germanyHowTuneInWorks.name)}
                    </UnderlineLink>
                  </>
                )}
                {!hideSitemap && (
                  <UnderlineLink
                    id={siteLinks.sitemap.id}
                    data-testid={siteLinks.sitemap.id}
                    className={css.siteLink}
                    to={siteLinks.sitemap.url}
                    target="_blank"
                    isNotReactRouterLink
                  >
                    {getLocalizedText(siteLinks.sitemap.name)}
                  </UnderlineLink>
                )}
                {/* TODO: This logic is temporary and only for Discord Beta until
                we sort out consent for global launch */}
                {!hideConsent && (
                  <>
                    <UnderlineLink
                      id={consentLink.id}
                      data-testid={consentLink.id}
                      className={css.siteLink}
                      onClick={actions.openOneTrustPreferenceCenter}
                    >
                      {getLocalizedText(consentLink.name)}
                    </UnderlineLink>

                    {userIsInWashington && (
                      <UnderlineLink
                        id={siteLinks.washingtonMyHealthMyDataAct.id}
                        data-testid={siteLinks.washingtonMyHealthMyDataAct.id}
                        className={css.siteLink}
                        to={siteLinks.washingtonMyHealthMyDataAct.url}
                        target="_blank"
                        isNotReactRouterLink
                      >
                        {getLocalizedText(
                          siteLinks.washingtonMyHealthMyDataAct.name,
                        )}
                      </UnderlineLink>
                    )}
                  </>
                )}
              </div>
            </div>
            {!hideSocial && (
              <div
                id="socialLinksContainer"
                data-testid="socialLinksContainer"
                className={css.socialLinksContainer}
              >
                {socialLinks.map(({ name, cssClass, url }) => (
                  <a
                    id={`footerSocial${name}`}
                    data-testid={`footerSocial${name}`}
                    key={`footerSocial${name}`}
                    className={classNames(
                      css.socialLink,
                      css.socialIcon,
                      css[cssClass],
                      { [css.noRight]: !isRightSideOpen },
                    )}
                    href={url}
                    target="_blank"
                    rel="noopener"
                  >
                    <span
                      data-testid={`${name}LinkSRText`}
                      className={css.srOnly}
                    >
                      {name}
                    </span>
                  </a>
                ))}
              </div>
            )}
          </div>
          {showDiagnostic && (
            <>
              <p style={{ marginBottom: '16px' }}>
                Diagnostics: Serial {showDiagnostic}
              </p>
              <dialog id="myDialog" ref={this.dialogRef}>
                <button
                  type="button"
                  id="closeButton"
                  onClick={this.closeDialog}
                >
                  Close
                </button>
                <br />
                <iframe
                  style={{ width: 600, height: 400 }}
                  src="//openspeedtest.com/speedtest"
                  loading="lazy"
                />
              </dialog>
              <button type="button" onClick={this.showDialog}>
                speedtest
              </button>
            </>
          )}
        </div>
      </footer>
    );
  }
}

// exported for testing
export function mapStateToProps(state) {
  const countryCode = selectCountryCode(state);
  const countryStateCode = selectCountryStateCode(state);
  const showDiagnostic = selectIsFord(state)
    ? selectTuneInUserSerial(state)
    : false;

  return {
    userIsInCalifornia: isUserInCalifornia(countryCode, countryStateCode),
    userIsInWashington: isUserInWashington(countryCode, countryStateCode),
    countryId: selectCountryId(state),
    termsOfServiceLink: selectTermsOfServiceLink(state),
    showDiagnostic,
  };
}

// exported for testing
export function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ openOneTrustPreferenceCenter }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
