export const ABOUT_US = 'site.aboutUs';
export const CAREERS = 'site.careers';
export const PRESS = 'site.press';
export const BLOG = 'site.blog';
export const GET_TUNEIN = 'site.getTuneIn';
export const PRIVACY = 'site.privacy';
export const GERMANY_PRIVACY = 'site.privacy.germany';
export const SITEMAP = 'site.sitemap';
export const YOUR_PRIVACY_CHOICES = 'site.dontSellMyData';
export const AD_DATA_OPT_OUT = 'site.ad.data.optOut';
export const ADVERTISERS = 'site.advertisers';
export const BROADCASTERS = 'site.broadcasters';
export const PODCASTERS = 'site.podcasters';
export const TERMS = 'site.terms';
export const LEGAL = 'site.legal';
export const VALUE_PROP = 'site.valueProp';
export const CONTACT_US = 'site.contactUs';
export const PRODUCT = 'site.product';
export const DEVICES = 'site.devices';
export const COMMUNITIES = 'site.communities';
export const BRAND = 'site.brand';
export const SUPPORT = 'site.support';
export const NEWS = 'site.news';
export const NEWS_AND_TALK = 'site.newsAndTalk';
export const MUSIC = 'site.music';
export const PODCASTS = 'site.podcasts';
export const AUDIOBOOKS = 'site.audiobooks';
export const SPORTS = 'site.sports';
export const LISTEN_NOW = 'site.listenNow';
export const MORE = 'site.more';
export const ONETRUST_PRIVACY_SETTINGS = 'site.gdpr.privacy.settings';
export const WASHINGTON_MY_HEALTH_MY_DATA_ACT =
  'site.washingtonMyHealthMyDataAct';
export const HOW_TUNEIN_WORKS = 'site.howTuneInWorks';
