import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ExternalLink from './ExternalLink';
import css from './underline-link.module.scss';

/*
  Component is to be used for links that require an underline, where either the underline should
  fade in/out, or should move down/up if the underline should be perpetual.
*/
const UnderlineLink = ({
  to,
  isNotReactRouterLink,
  children,
  className,
  underlineClassName,
  isDropDown,
  areColorsInverted,
  textEllipsis,
  ...otherProps
}) => {
  const baseProps = {
    className: classNames(
      css.link,
      {
        [css.textEllipsis]: textEllipsis,
        [css.invertedColors]: areColorsInverted,
      },
      className,
    ),
    ...otherProps,
  };
  const underlineBaseClass = isDropDown
    ? css.dropDownUnderline
    : css.fadeInUnderline;

  const underlineClass = classNames(underlineBaseClass, underlineClassName);

  const content = (
    <span
      className={classNames(css.contentWrapper, {
        [css.textEllipsisSpan]: textEllipsis,
      })}
    >
      {children}
      <i className={underlineClass} />
    </span>
  );

  if (isNotReactRouterLink) {
    return (
      <ExternalLink {...baseProps} href={to}>
        {content}
      </ExternalLink>
    );
  }

  if (to) {
    return (
      <Link {...baseProps} to={to}>
        {content}
      </Link>
    );
  }

  return <a {...baseProps}>{content}</a>;
};

UnderlineLink.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isNotReactRouterLink: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  underlineClassName: PropTypes.string,
  textEllipsis: PropTypes.bool,
  isDropDown: PropTypes.bool,
  areColorsInverted: PropTypes.bool,
  onClick: PropTypes.func,
};

UnderlineLink.defaultProps = {
  onClick: () => {},
};

export default UnderlineLink;
