import {
  selectBreakpoint,
  selectIsDiscord,
  selectIsMobile,
} from '../selectors/app';
import { isLarge } from './breakpoints';
import shouldShowPlayer from './playerStatus/shouldShowPlayer';

// On Web Desktop at or abov the Large breakpoint, the app toast should render in the Player component so that it can
// appear centered above the play button.
export function showAppToastInPlayer(state) {
  return (
    !selectIsMobile(state) &&
    !selectIsDiscord(state) &&
    shouldShowPlayer(state) &&
    isLarge(selectBreakpoint(state))
  );
}
