import interpolateComponents from 'interpolate-components';
import { localizationKeyMap } from './constants';
import css from './discord-controls.module.scss';

// TODO: move to Web Utils
export function truncateStringByLength(string, length = 0) {
  return string?.length > length ? `${string?.slice(0, length)}...` : string;
}

function interpolateButtonLabel(buttonLabel) {
  return interpolateComponents({
    mixedString: buttonLabel,
    components: {
      bold: <span className={css.bold} />,
    },
  });
}

export function getControlLabels(discordState, getLocalizedText) {
  const { activity, isHost } = discordState || {};
  const { infoLabelKey, buttonLabelKey } =
    localizationKeyMap[activity?.activityMode]?.[isHost] || {};
  const { nickname, username } = discordState.currentHost || {};

  return {
    infoLabel: infoLabelKey ? getLocalizedText(infoLabelKey) : '',
    buttonLabel: buttonLabelKey
      ? interpolateButtonLabel(
          getLocalizedText(buttonLabelKey).replace(
            '{host}',
            truncateStringByLength(nickname || username, 8),
          ),
        )
      : '',
  };
}

export function canShowParticipant(participant, discordState) {
  return (
    // don't show self
    participant.id !== discordState.user.id &&
    // show all if host || show only host if not host
    (discordState.isHost || participant.id === discordState.currentHost.id)
  );
}
