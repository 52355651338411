import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import css from './switch-button.module.scss';

export default function SwitchButton({
  isOn: isOnProp,
  onClick: onClickProp,
  enabledIconClassName,
  ariaLabelledBy,
  disabled,
}) {
  const [isOn, setIsOn] = useState(isOnProp || false);
  const containerClassNames = classNames(css.switchButtonContainer, {
    [css.enabled]: isOn,
  });

  useEffect(() => {
    if (typeof isOnProp === 'boolean') {
      setIsOn(isOnProp);
    }
  }, [isOnProp]);

  function onClick() {
    if (onClickProp) {
      return onClickProp?.(isOn);
    }

    setIsOn((v) => !v);
  }

  return (
    <button
      type="button"
      // eslint-disable-next-line jsx-a11y/aria-role
      role="switch"
      aria-checked={isOn}
      aria-labelledby={ariaLabelledBy}
      className={containerClassNames}
      data-testid="switchButtonContainer"
      onClick={onClick}
      disabled={disabled}
    >
      <div className={css.switchButton} data-testid="switchButton" />
      {enabledIconClassName && (
        <i
          className={classNames(css.icon, enabledIconClassName)}
          data-testid="switchButtonIcon"
        />
      )}
    </button>
  );
}

SwitchButton.propTypes = {
  isOn: PropTypes.bool,
  onClick: PropTypes.func,
  enabledIconClassName: PropTypes.string,
  ariaLabelledBy: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};
