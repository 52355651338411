import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import useOnMount from '../../hooks/useOnMount';

export function ScrollReset(
  { id, className, style, scrollHash, onScroll, children },
  forwardedRef,
) {
  const scrollRef = useRef(null);
  const primaryRef = forwardedRef || scrollRef;
  const scrollToTop = useCallback(() => {
    if (primaryRef.current) {
      primaryRef.current.scrollTop = 0;
      primaryRef.current.scrollLeft = 0;
    }
  }, [primaryRef]);

  useOnMount(scrollToTop);
  useEffect(scrollToTop, [scrollHash, scrollToTop]);

  return (
    <div
      data-testid="scrollReset"
      ref={primaryRef}
      id={id}
      className={className}
      style={style}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
}

ScrollReset.propTypes = {
  scrollHash: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onScroll: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default forwardRef(ScrollReset);
