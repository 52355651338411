import { isMacDesktop } from '@tunein/web-utils';
import { useSelector } from 'react-redux';
import {
  WEB_MAC_DESKTOP_HREF,
  WEB_WINDOWS_DESKTOP_HREF,
} from '../constants/experiments/desktop';
import { selectIsDesktop, selectUserAgent } from '../selectors/app';
import useExperiment from './useExperiment';

export function useDesktopDownloadLink() {
  const isDesktop = useSelector(selectIsDesktop);
  const userAgent = useSelector(selectUserAgent);
  const macDesktopHref = useExperiment(WEB_MAC_DESKTOP_HREF);
  const windowsDesktopHref = useExperiment(WEB_WINDOWS_DESKTOP_HREF);

  return {
    isDesktop,
    downloadLink: isMacDesktop(userAgent) ? macDesktopHref : windowsDesktopHref,
  };
}
