import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Pill, PillBar } from 'src/common/components/pillBar';
import { browsiesTitleToComponents } from '../constants';
import css from './browsies.module.scss';

export function Browsies({ getLocalizedText, pathname, browsies }) {
  return (
    browsies && (
      <PillBar
        className={css.bar}
        enableDragToScroll
        enableHorizontalWheelScroll
      >
        {browsies.map(({ title, path }) => {
          const MaybeComponent = browsiesTitleToComponents[title];
          const isSelected = path === pathname;
          const buttonTitle = getLocalizedText(title);

          return (
            <Pill
              key={`${title}-${path}`}
              title={buttonTitle}
              href={path}
              isSelected={isSelected}
            >
              {MaybeComponent ? (
                <MaybeComponent isSelected={isSelected} />
              ) : (
                <span
                  className={classNames(css.title, {
                    [css.selected]: isSelected,
                  })}
                >
                  {buttonTitle}
                </span>
              )}
            </Pill>
          );
        })}
      </PillBar>
    )
  );
}

Browsies.propTypes = {
  getLocalizedText: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  browsies: PropTypes.array.isRequired,
};
