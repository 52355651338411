import { selectIsUserSubscribed } from '../../selectors/me';
import {
  selectIsPlaying,
  selectNowPlayingCanShowAds,
} from '../../selectors/player';
import { isProfile } from '../guideItemTypes';

export function getStoreGuideItem(guideId, state) {
  return isProfile(guideId)
    ? state.profiles[guideId]
    : state.categories[guideId];
}

/**
 * Determines whether ads are appropriate for the user/context. While Mint also
 * makes this determination, this function is primarily used to prevent ad-related
 * elements from rendering (i.e., containers), which Mint has no way to control.
 * Eventually, Mint's ad eligibility logic should be replaced in favor of this function.
 *
 * @param {string|object} guideIdOrGuideItem
 * @returns {function}
 */
export default function determineAdEligibility(guideIdOrGuideItem) {
  return (state) => {
    if (selectIsUserSubscribed(state)) {
      return false;
    }

    const guideItem =
      typeof guideIdOrGuideItem === 'string'
        ? getStoreGuideItem(guideIdOrGuideItem, state)
        : guideIdOrGuideItem;
    const isPageAdEligible =
      guideItem?.metadata?.properties?.ads?.adEligible ?? true;
    const isPlaying = selectIsPlaying(state);
    const isStreamAdEligible = selectNowPlayingCanShowAds(state);

    return isPageAdEligible && (!isPlaying || isStreamAdEligible);
  };
}
