import classNames from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import css from './pill.module.scss';

export function Pill({ isSelected, href, onClick, children }) {
  const className = classNames(css.pill, {
    [css.selected]: isSelected,
    [css.link]: href,
  });

  return href ? (
    <Link to={href} className={className}>
      {children}
    </Link>
  ) : (
    <button type="button" className={className} onClick={onClick}>
      {children}
    </button>
  );
}

Pill.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};
