import classNames from 'clsx';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import {
  getSelectCategory,
  selectBrowsiesCategory,
} from 'src/common/selectors/categories';
import { getSelectProfile } from 'src/common/selectors/profiles';
import { MAP_VIEW } from '../../../constants/paths';
import usePrevious from '../../../hooks/usePrevious';
import { selectBreakpoint } from '../../../selectors/app';
import { isXXSmall } from '../../../utils/breakpoints';
import { getCssStyle } from '../../../utils/getCssStyle';
import isServer from '../../../utils/isServer';
import { DiscordControls } from '../../player/actionDrawer/DiscordControls';
import { NavBackArrowButton } from '../../shared/button/NavBackArrowButton';
import { Badge } from './Badge';
import { Browsies } from './Browsies';
import css from './browsies-header.module.scss';

// same as --discord-content-top-padding, plus 5 to offset odd styling deeply nested in container items
const DISCORD_CONTENT_OFFSET_PX = 5;

export function BrowsiesHeader({ guideId, contentScrollerRef, appContentRef }) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const {
    location: { pathname },
  } = useHistory();
  const categoryGuideItem = useSelector(getSelectCategory(guideId));
  const prevCategoryGuideItem = usePrevious(categoryGuideItem);
  const profileGuideItem = useSelector(getSelectProfile(guideId));
  const browsiesGuideItem = useSelector(selectBrowsiesCategory) || {};
  const browsiesHeaderRef = useRef();
  const [showBoxShadow, setShowBoxShadow] = useState(false);
  const [showBrowsies, setShowBrowsies] = useState(true);
  const contentTopPadding = Number.parseInt(
    getCssStyle('--discord-content-top-padding'),
    10,
  );
  const breakpoint = useSelector(selectBreakpoint);

  useEffect(() => {
    const handleWindowScroll = throttle(() => {
      const appContentTop = appContentRef?.current?.getBoundingClientRect().top;
      const browsiesHeaderHeight = browsiesHeaderRef?.current?.offsetHeight;
      const browsiesHeaderScrollBoundary =
        browsiesHeaderHeight -
          (contentTopPadding + DISCORD_CONTENT_OFFSET_PX) || 0;

      setShowBoxShadow(appContentTop < browsiesHeaderScrollBoundary);
    }, 100);
    const currentContentScroller = contentScrollerRef?.current;

    currentContentScroller?.addEventListener('scroll', handleWindowScroll);

    return () => {
      currentContentScroller?.removeEventListener('scroll', handleWindowScroll);
    };
  }, [appContentRef, contentTopPadding, contentScrollerRef]);

  useEffect(() => {
    if (
      browsiesGuideItem.paths &&
      categoryGuideItem !== prevCategoryGuideItem
    ) {
      setShowBrowsies(browsiesGuideItem.paths[pathname]);
    }
  }, [
    browsiesGuideItem.paths,
    categoryGuideItem,
    pathname,
    prevCategoryGuideItem,
  ]);

  return (
    <div
      ref={browsiesHeaderRef}
      className={classNames(css.browsiesHeader, {
        [css.categoryBrowsiesHeader]: !profileGuideItem,
        [css.showBoxShadow]: showBoxShadow && !profileGuideItem,
        [css.hide]: pathname === MAP_VIEW,
      })}
    >
      <div className={css.navAndControls}>
        <Badge
          showBackArrow={Boolean(profileGuideItem) && isXXSmall(breakpoint)}
        />
        {!profileGuideItem && (
          <div className={css.discordControls}>
            <DiscordControls
              buttonClassName={css.discordControlsButton}
              popoverClassName={css.discordControlsPopover}
            />
          </div>
        )}
      </div>
      {showBrowsies ? (
        <Browsies
          pathname={pathname}
          getLocalizedText={getLocalizedText}
          browsies={browsiesGuideItem.containerItems}
        />
      ) : (
        <div className={css.backAndTitle}>
          <NavBackArrowButton />
          <span className={css.title}>{categoryGuideItem?.title}</span>
        </div>
      )}
    </div>
  );
}

BrowsiesHeader.propTypes = {
  guideId: PropTypes.string,
  contentScrollerRef: PropTypes.shape({
    current: isServer() ? PropTypes.any : PropTypes.instanceOf(Element),
  }),
  appContentRef: PropTypes.shape({
    current: isServer() ? PropTypes.any : PropTypes.instanceOf(Element),
  }),
};
