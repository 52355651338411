import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import oneTrust from '../consent/OneTrust';
import shouldShowFloatingGdprButton from '../consent/shouldShowFloatingGdprButton';

const useOneTrustButton = ({ isLeftSideDocked }) => {
  const showFloatingGdprButton = useSelector((state) =>
    shouldShowFloatingGdprButton(state, isLeftSideDocked),
  );

  useEffect(() => {
    oneTrust.handleFloatingButtonChange(showFloatingGdprButton);
  }, [showFloatingGdprButton]);

  return { showFloatingGdprButton };
};

export default useOneTrustButton;
