import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { desktopFuncNames } from '../../constants/desktop';
import {
  DESKTOP_UPDATE_BANNER_BUTTON_LABEL,
  DESKTOP_UPDATE_BANNER_MANUAL_BUTTON_LABEL,
  DESKTOP_UPDATE_BANNER_MANUAL_MESSAGE,
  DESKTOP_UPDATE_BANNER_MESSAGE,
} from '../../constants/localizations/desktop';
import { useDesktopDownloadLink } from '../../hooks/useDesktopDownloadLink';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectShowManualUpdateBanner } from '../../selectors/desktop';
import cssVars from '../../styles/variables';
import invokeDesktopFunction from '../../utils/desktop/invokeDesktopFunction';
import { TopBannerContainer } from '../banner/TopBannerContainer';
import PillButton from '../shared/button/PillButton';
import PillButtonLink from '../shared/button/PillButtonLink';
import css from './installDesktopUpdateBanner.module.scss';

function handleBannerClick() {
  // quitDesktopAndInstallUpdate defined in gemini-desktop
  invokeDesktopFunction(desktopFuncNames.quitDesktopAndInstallUpdate);
}

export default function InstallDesktopUpdateBanner() {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const showManualUpdateBanner = useSelector(selectShowManualUpdateBanner);
  const { downloadLink } = useDesktopDownloadLink();
  const bannerMessageKey = showManualUpdateBanner
    ? DESKTOP_UPDATE_BANNER_MANUAL_MESSAGE
    : DESKTOP_UPDATE_BANNER_MESSAGE;
  const buttonLabelKey = showManualUpdateBanner
    ? DESKTOP_UPDATE_BANNER_MANUAL_BUTTON_LABEL
    : DESKTOP_UPDATE_BANNER_BUTTON_LABEL;
  const Button = showManualUpdateBanner ? PillButtonLink : PillButton;
  const clickHandlerProp = showManualUpdateBanner
    ? { externalHref: downloadLink }
    : { onClick: handleBannerClick };

  return (
    <TopBannerContainer className={css.topBanner} enableBannerOffset>
      <div className={css.messageWrapper}>
        <div className={css.updateIcon} />
        <div data-testid="updateMessage">
          {getLocalizedText(bannerMessageKey)}
        </div>
      </div>
      <Button
        id="installDesktopUpdateButton"
        className={css.installButton}
        label={getLocalizedText(buttonLabelKey)}
        backgroundColor={cssVars['--t-sharp']}
        textColor={cssVars['--ink-dark']}
        {...clickHandlerProp}
      />
    </TopBannerContainer>
  );
}
