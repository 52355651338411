import classNames from 'clsx';
import throttle from 'lodash/throttle';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import EventListener from 'react-event-listener';
import { connect } from 'react-redux';
import { setTopBannerHeight } from '../../actions/app';
import css from './top-banner-container.module.scss';

class _TopBannerContainer extends Component {
  static propTypes = {
    className: PropTypes.string,
    setTopBannerHeight: PropTypes.func.isRequired,
    enableBannerOffset: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    contentWrapperStyles: {},
    bannerColor: '',
  };

  constructor(props) {
    super(props);

    this.bannerEl = createRef();
    this.updateDimensions = throttle(this.updateDimensions.bind(this), 0, {
      leading: false,
    });
  }

  componentDidMount() {
    this.updateDimensions();
  }

  updateDimensions() {
    this.props.setTopBannerHeight(this.bannerEl.current.clientHeight);
  }

  render() {
    const { children, className, enableBannerOffset } = this.props;

    const banner = (
      <div className={classNames(css.container, className)} ref={this.bannerEl}>
        {children}
      </div>
    );

    return enableBannerOffset ? (
      <EventListener target="window" onResize={this.updateDimensions}>
        {banner}
      </EventListener>
    ) : (
      banner
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTopBannerHeight: (height) => dispatch(setTopBannerHeight(height)),
  };
}

export const TopBannerContainer = connect(
  null,
  mapDispatchToProps,
)(_TopBannerContainer);
