import Drawer from 'material-ui/Drawer';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FREESTAR_ENABLED } from 'src/common/constants/experiments/ads';
import useExperiment from 'src/common/hooks/useExperiment';
import cssVariables from 'src/common/styles/variables';
import {
  getDisplayAdAttributes,
  gptSlotNames,
} from 'src/common/utils/ads/constants';
import { isProfile } from 'src/common/utils/guideItemTypes';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectTopBannerHeight } from '../../selectors/app';
import { selectShouldShowDesktopUpdateBanner } from '../../selectors/desktop';
import getTopBannerOffsetStyles from '../../utils/banner/getTopBannerOffsetStyles';
import Scrollbar from '../Scrollbar';
import DisplayAd from '../ads/DisplayAd';
import showProfileAdInContentArea from '../profile/utils/showProfileAdInContentArea';
import UpsellRightSidebar from '../subscription/UpsellRightSidebar';
import css from './right-side.module.scss';

export default function RightSide({
  hideRightSidebarUpsell,
  matchUrl,
  guideId,
  canShowAds,
  breakpoint,
} = {}) {
  const {
    location: { pathname },
  } = useContext(LocationAndLocalizationContext);
  const shouldShowDesktopUpdateBanner = useSelector(
    selectShouldShowDesktopUpdateBanner,
  );
  const topBannerHeight = useSelector(selectTopBannerHeight);
  const isFreestarEnabled = useExperiment(FREESTAR_ENABLED);

  const drawerStyle = {
    // Adding pointer-events:none makes it so that pointer events do not bubble up to the
    // target element. This includes scroll events that might block scrolling of the main content view.
    pointerEvents: 'none',
    boxShadow: 'none',
    right: cssVariables['--browser-max-scrollbar-width'],
    paddingLeft: cssVariables['--rightSide-left-padding'],
    backgroundColor: cssVariables['--content-area-background-color-hex'],
    overflow: 'hidden',
    ...(shouldShowDesktopUpdateBanner &&
      getTopBannerOffsetStyles(topBannerHeight)),
  };

  const isProfileGuideItem = guideId ? isProfile(guideId) : false;
  const adSlotId = isProfileGuideItem
    ? 'profile_banner_right_side'
    : 'browse_banner_right_side';
  const adSlotName = isProfileGuideItem ? 'profile_side' : 'browse_side';
  const shouldRenderDisplayAd =
    canShowAds &&
    (!isProfileGuideItem || !showProfileAdInContentArea(breakpoint));

  const rightSideWidth = cssVariables['--rightSide-width'];
  const rightSideLeftPadding = cssVariables['--rightSide-left-padding'];

  return (
    <div className={css.wrapper}>
      <Drawer
        width={rightSideWidth}
        openSecondary
        docked
        open
        containerStyle={drawerStyle}
      >
        <Scrollbar
          width={rightSideWidth - rightSideLeftPadding}
          darkThemeEnabled
        >
          <div className={css.container}>
            {shouldRenderDisplayAd && (
              <DisplayAd
                key={adSlotId}
                slotName={adSlotName}
                isProfile={isProfileGuideItem}
                matchUrl={matchUrl}
                guideId={guideId}
                {...getDisplayAdAttributes(
                  gptSlotNames[adSlotName],
                  isFreestarEnabled,
                  !isFreestarEnabled && adSlotId,
                )}
              />
            )}
            {/* important: the key prop allows the component to remount and log upsell activity on pathname change */}
            {!hideRightSidebarUpsell && (
              <UpsellRightSidebar key={pathname} guideId={guideId} />
            )}
          </div>
        </Scrollbar>
      </Drawer>
    </div>
  );
}

RightSide.propTypes = {
  guideId: PropTypes.string,
  hideRightSidebarUpsell: PropTypes.bool,
  matchUrl: PropTypes.string.isRequired,
  canShowAds: PropTypes.bool.isRequired,
};
