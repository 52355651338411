import * as localizations from '../localizations/site';

import {
  AUDIOBOOKS_BROWSE_PATH,
  HOME_BROWSE_PATH,
  MUSIC_BROWSE_PATH,
  NEWS_BROWSE_PATH,
  PODCASTS_BROWSE_PATH,
  PREMIUM_BROWSE_PATH,
  SPORTS_BROWSE_PATH,
} from '../paths';

import {
  GERMANY_PRIVACY,
  HELP_URL,
  HOW_TUNEIN_WORKS,
  PRIVACY,
  TOS,
  WASHINGTON_MY_HEALTH_MY_DATA_ACT_URL,
  httpsBaseUrl,
} from '../cmsLinks';

export default {
  aboutUs: {
    id: 'footerAboutUs',
    name: localizations.ABOUT_US,
    url: `${httpsBaseUrl}about/`,
  },
  careers: {
    id: 'footerCareers',
    name: localizations.CAREERS,
    url: `${httpsBaseUrl}careers/`,
  },
  press: {
    id: 'footerPress',
    name: localizations.PRESS,
    url: `${httpsBaseUrl}press/`,
  },
  getTuneIn: {
    id: 'footerGetTuneIn',
    name: localizations.DEVICES,
    url: `${httpsBaseUrl}get-tunein/`,
  },
  support: {
    id: 'footerSupport',
    name: localizations.SUPPORT,
    url: HELP_URL,
  },
  privacy: {
    id: 'footerPrivacy',
    name: localizations.PRIVACY,
    url: PRIVACY,
  },
  sitemap: {
    id: 'footerSitemap',
    name: localizations.SITEMAP,
    url: `${httpsBaseUrl}sitemap/`,
  },
  oneTrustCcpaPreferenceCenter: {
    id: 'footerOneTrustCcpaPreferenceCenter',
    name: localizations.YOUR_PRIVACY_CHOICES,
  },
  oneTrustPreferenceCenter: {
    id: 'footerOneTrustPreferenceCenter',
    name: localizations.ONETRUST_PRIVACY_SETTINGS,
    hiddenLinkProps: {
      id: 'ot-sdk-btn',
      className: 'ot-sdk-show-settings',
      style: { display: 'none' },
    },
  },
  broadcasters: {
    id: 'footerBroadcasters',
    name: localizations.BROADCASTERS,
    url: `${httpsBaseUrl}broadcasters/`,
  },
  podcasters: {
    id: 'footerPodcasters',
    name: localizations.PODCASTERS,
    url: `${httpsBaseUrl}podcasters/`,
  },
  policies: {
    id: 'footerPolicies',
    name: localizations.TERMS,
    url: TOS,
  },
  contactUs: {
    id: 'footerContactUs',
    name: localizations.CONTACT_US,
    url: `${httpsBaseUrl}contact/`,
  },
  brand: {
    id: 'footerBrand',
    name: localizations.BRAND,
    url: `${httpsBaseUrl}business/`,
  },
  product: {
    id: 'footerProduct',
    name: localizations.PRODUCT,
    url: HOME_BROWSE_PATH,
  },
  music: {
    id: 'footerMusic',
    name: localizations.MUSIC,
    url: MUSIC_BROWSE_PATH,
  },
  sports: {
    id: 'footerSports',
    name: localizations.SPORTS,
    url: SPORTS_BROWSE_PATH,
  },
  podcasts: {
    id: 'footerPodcasts',
    name: localizations.PODCASTS,
    url: PODCASTS_BROWSE_PATH,
  },
  audiobooks: {
    id: 'footerAudiobooks',
    name: localizations.AUDIOBOOKS,
    url: AUDIOBOOKS_BROWSE_PATH,
  },
  news: {
    id: 'footerNews',
    name: localizations.NEWS_AND_TALK,
    url: NEWS_BROWSE_PATH,
  },
  listenNow: {
    id: 'footerListenNow',
    name: localizations.LISTEN_NOW,
    url: HOME_BROWSE_PATH,
  },
  more: {
    id: 'footerMore',
    name: localizations.MORE,
    url: PREMIUM_BROWSE_PATH,
  },
  germanyPrivacy: {
    id: 'footerGermanyPrivacy',
    name: localizations.GERMANY_PRIVACY,
    url: GERMANY_PRIVACY,
  },
  germanyHowTuneInWorks: {
    id: 'footerGermanyHowTuneInWorks',
    name: localizations.HOW_TUNEIN_WORKS,
    url: HOW_TUNEIN_WORKS,
  },
  washingtonMyHealthMyDataAct: {
    id: 'footerWashingtonMyHealthMyDataAct',
    name: localizations.WASHINGTON_MY_HEALTH_MY_DATA_ACT,
    url: WASHINGTON_MY_HEALTH_MY_DATA_ACT_URL,
  },
};
