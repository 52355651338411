import SearchIcon from 'src/common/components/shared/svgIcons/SearchIcon';
import HeartEmptyIcon from 'src/common/components/shared/svgIcons/player/HeartEmptyIcon';
import cssVars from 'src/common/styles/variables';

export const browsiesTitleToComponents = {
  'Discord Search': ({ isSelected }) => (
    <SearchIcon
      id="browsiesSearchIcon"
      dataTestId="browsiesSearchIcon"
      fill={cssVars[isSelected ? '--secondary-color-5' : '--anti-flash-white']}
      height="21px"
      width="21px"
    />
  ),
  Library: ({ isSelected }) => (
    <HeartEmptyIcon
      id="browsiesFavoritesIcon"
      dataTestId="browsiesFavoritesIcon"
      fill={cssVars[isSelected ? '--secondary-color-5' : '--anti-flash-white']}
      height="21px"
      width="21px"
    />
  ),
};
