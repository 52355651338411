import { isTopic } from '@tunein/web-common';
import get from 'lodash/get';
import { isCategory, isProfile } from '../guideItemTypes';

// determine if matches any pages with regular content (profiles/categorys/topics etc)
export default function isContentPage(routeProps) {
  if (routeProps && (routeProps.isUserPage || routeProps.isSearchPage)) {
    return true;
  }

  const guideIdOrSlug = get(routeProps, 'guideContext.guideId', undefined);

  if (!guideIdOrSlug) {
    return false;
  }

  return !!(
    isCategory(guideIdOrSlug) ||
    isProfile(guideIdOrSlug) ||
    isTopic(guideIdOrSlug)
  );
}
