import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectInfoBannerMessage } from '../../../selectors/app';
import { InfoIcon } from '../../shared/svgIcons/InfoIcon';
import { TopBannerContainer } from '../TopBannerContainer';
import css from './info-banner.module.scss';

export function InfoBanner() {
  const message = useSelector(selectInfoBannerMessage);

  useEffect(() => {
    const html = document.querySelector('html');

    if (message) {
      html.classList.add('infoBannerVisible');
    } else {
      html.classList.remove('infoBannerVisible');
    }
  }, [message]);

  return message ? (
    <TopBannerContainer className={css.infoBanner}>
      <InfoIcon height="16px" width="16px" opacity="0.6" />
      <span className={css.message}>{message}</span>
    </TopBannerContainer>
  ) : null;
}
