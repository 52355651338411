// download desktop app dialog, these keys were originally for windows but the
// copy is generic, so just renaming the consts to use for both windows and mac
export const DOWNLOAD_DESKTOP_TITLE = 'download.windows.desktop.title';
export const DOWNLOAD_DESKTOP_SUBTITLE = 'download.windows.desktop.subtitle';
export const DOWNLOAD_DESKTOP_CONTENT = 'download.windows.desktop.content';
export const DOWNLOAD_DESKTOP_BUTTON = 'download.windows.desktop.button';

// desktop update install banner
export const DESKTOP_UPDATE_BANNER_MESSAGE =
  'web.desktop.update.banner.message';
export const DESKTOP_UPDATE_BANNER_BUTTON_LABEL =
  'web.desktop.update.banner.button.label';

export const DESKTOP_UPDATE_BANNER_MANUAL_MESSAGE =
  'web.desktop.update.banner.manual.message';
export const DESKTOP_UPDATE_BANNER_MANUAL_BUTTON_LABEL =
  'web.desktop.update.banner.manual.button.label';
