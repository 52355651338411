import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NAV_BACK_BUTTON } from 'src/common/constants/localizations/button';
import { customHistory } from 'src/common/hooks/useCustomHistory';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import { selectIsMobile } from 'src/common/selectors/app';
import assetUrl from 'src/common/utils/assetUrl';
import { isInDiscordIFrame } from 'src/common/utils/discord';
import usePrevious from '../../../../hooks/usePrevious';
import css from './nav-back-arrow-button.module.scss';

const leftArrowSrc = assetUrl('assets/img/shared/arrow-left.svg');

export function NavBackArrowButton({ onClick }) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const history = useHistory();
  const previousLocationPathname = usePrevious(history.location.pathname);
  const isMobile = useSelector(selectIsMobile);

  function onGoBack() {
    // React Router's history.goBack() doesn't work as expected in Discord's iframe on mobile, so we need to use our
    // custom history class that mimics a goBack() by logging path changes to an internal stack and then navigating
    // _forward_ to the previous path.
    //
    // For Android, calling goBack() doesn't work at all; no navigation occurs.
    // For iOS, calling goBack() causes the audio element to emit an `ended` event ~10 seconds after the navigation.
    if (isInDiscordIFrame() && isMobile) {
      customHistory.goBack();
      return;
    }

    history.goBack();

    setTimeout(() => {
      // If the location pathname has not changed after calling goBack(), this indicates that it didn't
      // work, so we'll use our custom history implementation to force a navigation to the previous path.
      if (
        isInDiscordIFrame() &&
        history.location.pathname === previousLocationPathname
      ) {
        customHistory.goBack();
      }
    });
  }

  return (
    <button
      type="button"
      className={css.backButton}
      aria-label={getLocalizedText(NAV_BACK_BUTTON)}
      onClick={onClick || onGoBack}
    >
      {/* The empty alt below is intentional, as screen readers will use the aria-label on the button */}
      <img src={leftArrowSrc} width="30" height="30" alt="" />
    </button>
  );
}

NavBackArrowButton.propTypes = {
  onClick: PropTypes.func,
};
