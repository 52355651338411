import { useEffect, useRef } from 'react';
import { useSelector, useStore } from 'react-redux';
import { setIsBrazeInitialized } from '../actions/app';
import {
  logClientError,
  logEULAActivity,
  logReceivedContentCardsCount,
} from '../actions/logging';
import { addMeConsent } from '../actions/me';
import { BRAZE_SESSION_TIMEOUT_IN_SECONDS } from '../constants/experiments/braze';
import { selectIsBrazeInitialized, selectIsDiscord } from '../selectors/app';
import { selectIsAuthenticated } from '../selectors/auth';
import {
  selectIsBrazeContentCardsEnabled,
  selectIsBrazeEnabled,
  selectLocation,
} from '../selectors/config';
import { selectUserAccountId } from '../selectors/me';
import { brazeOnAuth, initBraze } from '../utils/braze/brazeHelper';
import isContentPage from '../utils/subscription/isContentPage';
import useActions from './useActions';
import useExperiment from './useExperiment';
import usePrevious from './usePrevious';

export default function useBraze(routeProps) {
  const actions = useActions({
    logClientError,
    setIsBrazeInitialized,
    logReceivedContentCardsCount,
    logEULAActivity,
    addMeConsent,
  });
  const isBrazeFriendlyPage = isContentPage(routeProps) || routeProps.isLanding;
  const isBrazeExperimentEnabled = useSelector(selectIsBrazeEnabled);
  const isBrazeInitialized = useSelector(selectIsBrazeInitialized);
  const userAccountId = useSelector(selectUserAccountId);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const previousIsAuthenticated = usePrevious(isAuthenticated);
  const didAuthChange = isAuthenticated !== previousIsAuthenticated;
  const isDiscord = useSelector(selectIsDiscord);
  const isBrazeContentCardsExperimentEnabled = useSelector(
    selectIsBrazeContentCardsEnabled,
  );
  const brazeSessionTimeoutInSeconds = useExperiment(
    BRAZE_SESSION_TIMEOUT_IN_SECONDS,
  );
  const userLocation = useSelector(selectLocation);
  const stateRef = useRef({});
  const reduxStore = useStore();

  useEffect(() => {
    stateRef.current = {
      isBrazeFriendlyPage,
      isAuthenticated,
      userAccountId,
      userLocation,
    };
  });

  useEffect(() => {
    if (isBrazeExperimentEnabled) {
      initBraze({
        actions,
        isDiscord,
        stateRef,
        isBrazeContentCardsExperimentEnabled,
        brazeSessionTimeoutInSeconds,
      });
    }
  }, [
    actions,
    isDiscord,
    isBrazeExperimentEnabled,
    isBrazeContentCardsExperimentEnabled,
    brazeSessionTimeoutInSeconds,
  ]);

  useEffect(() => {
    if (isBrazeInitialized && didAuthChange) {
      brazeOnAuth(isAuthenticated, userLocation, actions, reduxStore);
    }
  }, [
    isBrazeInitialized,
    didAuthChange,
    userLocation,
    isAuthenticated,
    actions,
    reduxStore,
  ]);
}
