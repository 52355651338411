import { MuiThemeProvider } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import PropTypes from 'prop-types';
import cssVariables from './variables';

const sliderBaseStyles = {
  selectionColor: cssVariables['--secondary-color-5'],
  rippleColor: cssVariables['--secondary-color-5'],
  trackColor: '#d0d1d1',
  trackColorSelected: '#d5d5d5',
  handleColorZero: cssVariables['--secondary-color-5'],
  handleFillColor: cssVariables['--secondary-color-5'],
  handleSize: 12,
  handleSizeActive: 12,
  trackSize: 4,
};

export const videoAdVolumeTheme = getMuiTheme({
  userAgent: false,
  slider: {
    ...sliderBaseStyles,
    selectionColor: '#fff',
    rippleColor: '#d0d1d1',
    trackColor: cssVariables['--secondary-color-7'],
    trackColorSelected: cssVariables['--secondary-color-7'],
    handleColorZero: '#fff',
    handleFillColor: '#fff',
  },
});

export const ThemeProvider = ({ children }) => (
  <MuiThemeProvider
    muiTheme={getMuiTheme({
      fontFamily: cssVariables['--primary-font'],
      palette: {
        accent1Color: cssVariables['--primary-color-1'],
        accent2Color: cssVariables['--primary-color-1'],
        accent3Color: cssVariables['--primary-color-1'],
        primary1Color: cssVariables['--primary-color-1'],
        primary2Color: '#339A8F',
        primary3Color: cssVariables['--primary-color-1'],
        textColor: '#000',
        canvasColor: cssVariables['--tunein-grey'],
        borderColor: cssVariables['--tunein-grey-2'],
      },
      slider: {
        ...sliderBaseStyles,
      },
    })}
  >
    {/* biome-ignore lint/complexity/noUselessFragments: redundant fragment addresses noisy prop types warning from MUI */}
    <>{children}</>
  </MuiThemeProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
