export default [
  {
    name: 'Facebook',
    cssClass: 'socialLinkFacebook',
    url: 'https://facebook.com/tunein',
  },
  {
    name: 'Instagram',
    cssClass: 'socialLinkInstagram',
    url: 'https://instagram.com/tunein',
  },
  {
    name: 'Twitter',
    cssClass: 'socialLinkTwitter',
    url: 'https://twitter.com/tunein',
  },
];
