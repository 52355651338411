import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import useOnMount from 'src/common/hooks/useOnMount';
import { ElementEventHandlers } from '../utils';
import css from './pill-bar.module.scss';

export function PillBar({
  enableHorizontalWheelScroll,
  enableDragToScroll,
  className,
  children,
}) {
  const pillBarRef = useRef(null);

  useOnMount(() => {
    const handlers = new ElementEventHandlers(pillBarRef.current);

    if (enableHorizontalWheelScroll) {
      handlers.enableHorizontalWheelScroll();
    }

    if (enableDragToScroll) {
      handlers.enableDragToScroll();
    }

    return () => {
      handlers.removeAllListeners();
    };
  });

  return (
    <div className={classNames(css.pillBar, className)} ref={pillBarRef}>
      {children}
    </div>
  );
}

PillBar.propTypes = {
  enableHorizontalWheelScroll: PropTypes.bool,
  enableDragToScroll: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
