import classNames from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  discordManager,
  getDiscordAvatarUrl,
  hostModes,
} from 'src/common/components/discord/discordManager';
import Popover from 'src/common/components/shared/Popover';
import SwitchButton from 'src/common/components/shared/button/SwitchButton';
import CaretDown from 'src/common/components/shared/svgIcons/CaretDown';
import { useOutsideClickControl } from 'src/common/hooks/useOutsideClickControl';
import usePrevious from 'src/common/hooks/usePrevious';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import { selectDiscordState } from 'src/common/selectors/app';
import { selectCanScrub } from 'src/common/selectors/player';
import {
  DISABLED_BUTTON_TIMEOUT,
  DISCORD_IS_HOST_USER_LIST_LABEL,
  DISCORD_IS_NOT_HOST_USER_LIST_LABEL,
  DISCORD_USER_AVATAR_IMAGE_LABEL,
} from './constants';
import css from './discord-controls.module.scss';
import {
  canShowParticipant,
  getControlLabels,
  truncateStringByLength,
} from './utils';

let enableButtonsTimeoutId;

export function DiscordControls({ buttonClassName, popoverClassName }) {
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(false);
  const discordState = useSelector(selectDiscordState);
  const canScrub = useSelector(selectCanScrub);
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const { infoLabel, buttonLabel } = getControlLabels(
    discordState,
    getLocalizedText,
  );
  const { handleOnClick, isOpen, containerRef } = useOutsideClickControl(true);
  const previousDiscordState = usePrevious(discordState);
  const [isSharedMode, setIsSharedMode] = useState(
    discordState.activity.activityMode === hostModes.shared,
  );

  useEffect(() => {
    const { activityMode, isHost } = discordState.activity;
    const { activityMode: previousActivityMode, isHost: previousIsHost } =
      previousDiscordState?.activity || {};

    if (activityMode === previousActivityMode && isHost === previousIsHost) {
      return;
    }

    clearTimeout(enableButtonsTimeoutId);
    setAreButtonsDisabled(false);
    // as the mode switch is controlled state, we need to correct local state against external state
    setIsSharedMode(activityMode === hostModes.shared);
  }, [discordState, previousDiscordState]);

  // In the event that the state change fails, we'll re-enable buttons after a timeout
  const enableButtonsAfterTimeout = useCallback(() => {
    enableButtonsTimeoutId = setTimeout(() => {
      setAreButtonsDisabled(false);
      // as the mode switch is controlled state, we need to correct local state against external state
      setIsSharedMode(discordState.activity.activityMode === hostModes.shared);
    }, DISABLED_BUTTON_TIMEOUT);
  }, [discordState.activity.activityMode]);

  function onHostChange(newHostId) {
    if (!discordState.isHost) {
      return;
    }

    setAreButtonsDisabled(true);
    discordManager.setHost(newHostId);
    enableButtonsAfterTimeout();
  }

  const onModeChange = useCallback(() => {
    if (!discordState.isHost) {
      return;
    }

    setAreButtonsDisabled(true);
    setIsSharedMode(!isSharedMode);
    discordManager.setMode(isSharedMode ? hostModes.hosted : hostModes.shared);
    enableButtonsAfterTimeout();
  }, [discordState.isHost, enableButtonsAfterTimeout, isSharedMode]);

  return (
    <div ref={containerRef}>
      <button
        type="button"
        className={classNames(css.button, css.actionButton, buttonClassName, {
          [css.open]: isOpen,
        })}
        onClick={handleOnClick}
        disabled={areButtonsDisabled}
      >
        <img
          src={
            discordState?.isHost
              ? discordState?.user?.avatarUri
              : getDiscordAvatarUrl(discordState?.currentHost)
          }
          className={css.avatar}
          alt={getLocalizedText(DISCORD_USER_AVATAR_IMAGE_LABEL)}
        />
        {!canScrub && <span className={css.text}>{buttonLabel}</span>}
        <CaretDown className={classNames(css.arrow, { [css.up]: isOpen })} />
      </button>

      <Popover
        isOpen={isOpen}
        className={classNames(css.popover, popoverClassName)}
        contentClassName={classNames(css.popoverContent, {
          [css.hostOnly]: discordState.participants?.length === 1,
        })}
      >
        <>
          <div className={css.modeContainer}>
            <span id="discord-controls-info-label" className={css.text}>
              {infoLabel}
            </span>
            {discordState.isHost && (
              <SwitchButton
                onClick={onModeChange}
                isOn={isSharedMode}
                ariaLabelledBy="discord-controls-info-label"
                disabled={areButtonsDisabled}
              />
            )}
          </div>
          {discordState.participants?.length > 1 && (
            <>
              <hr />
              <span className={classNames(css.text, css.userListLabel)}>
                {getLocalizedText(
                  discordState.isHost
                    ? DISCORD_IS_HOST_USER_LIST_LABEL
                    : DISCORD_IS_NOT_HOST_USER_LIST_LABEL,
                )}
              </span>
            </>
          )}
          {isOpen && (
            <div className={classNames('scroller', css.participantsContainer)}>
              {discordState.participants?.map(
                (participant) =>
                  canShowParticipant(participant, discordState) && (
                    <button
                      key={participant.id}
                      type="button"
                      aria-labelledby="discord-controls-username"
                      className={classNames(css.button, css.participant, {
                        [css.disabled]: !discordState.isHost,
                        [css.single]: discordState.participants?.length === 2,
                      })}
                      onClick={() => onHostChange(participant.id)}
                      disabled={areButtonsDisabled}
                    >
                      <img
                        className={css.avatar}
                        src={getDiscordAvatarUrl(participant)}
                        alt={getLocalizedText(DISCORD_USER_AVATAR_IMAGE_LABEL)}
                      />
                      <span id="discord-controls-username" className={css.text}>
                        {truncateStringByLength(
                          participant.nickname || participant.username,
                          20,
                        )}
                      </span>
                    </button>
                  ),
              )}
            </div>
          )}
        </>
      </Popover>
    </div>
  );
}

DiscordControls.propTypes = {
  buttonClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
};
